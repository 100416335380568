import { apiRequestExternal } from "./util.js";

const endpoint = `https://api.tminal.com/static-site-mailer`;

function submit(data) {
  console.log(data);
  return apiRequestExternal(endpoint, "POST", data);
}

const contact = { submit };

export default contact;
