import React from "react";
import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import Section from "./Section";
import SectionHeader from "./SectionHeader";

const useStyles = makeStyles((theme) => ({
  itemsContainer: {
    marginTop: 60,
  },
  row: {
    // Reverse every other row
    "&:nth-of-type(even)": {
      flexDirection: "row-reverse",
    },

    // Spacing between rows
    "&:not(:last-child)": {
      marginBottom: `${theme.spacing(3)}px`,
    },
  },
  figure: {
    maxWidth: 300,
    margin: "30px auto",
  },
  image: {
    height: "auto",
    maxWidth: "100%",
    display: "block",
    marginLeft: "auto",
    marginRight: "auto"
  },
}));

function ProccessSection(props) {
  const classes = useStyles();

  const items = [
    {
      title: "Design",
      description:
        "Our initial stage is to work with you in to design and analyse the solution you want to bring to market.",
      image: "images/undraw_design_components_9vy6.svg",
    },
    {
      title: "Develop",
      description:
        "After the initial stage, we'll bring that design to life into a product. This is an open feedback loop, where we develop features using agile methodologies to ensure that the product is keeping to our initial design principals.",
      image: "images/undraw_developer_activity_re_39tg.svg",
    },
    {
      title: "Test",
      description: "With every development we do extensive testing to ensure that a release will be as successful as possible. This testing is a mix of both functional and expoloritory testing. We can also provide early access to you and trial groups.",
      image: "images/undraw_usability_testing_re_uu1g.svg",
    },
    {
      title: "Release",
      description: "Once you are happy with the product, we will then manage the release - whether if it is an online service, API and/or a mobile development release!",
      image: "images/undraw_online_party_re_7t6g.svg",
    },
  ];

  return (
    <Section
      bgColor={props.bgColor}
      size={props.size}
      bgImage={props.bgImage}
      bgImageOpacity={props.bgImageOpacity}
    >
      <Container>
        <SectionHeader
          title={props.title}
          subtitle={props.subtitle}
          size={4}
          textAlign="center"
        />
        <Container
          maxWidth="md"
          disableGutters={true}
          className={classes.itemsContainer}
        >
          {items.map((item, index) => (
            <Grid
              className={classes.row}
              container={true}
              item={true}
              alignItems="center"
              spacing={4}
              key={index}
            >
              <Grid item={true} xs={12} md={6}>
                <Box
                  textAlign={{
                    xs: "center",
                    md: "left",
                  }}
                >
                  <Typography variant="h5" gutterBottom={true}>
                    {item.title}
                  </Typography>
                  <Typography variant="subtitle1">
                    {item.description}
                  </Typography>
                </Box>
              </Grid>
              <Grid item={true} xs={12} md={6}>
                <figure className={classes.figure}>
                  <img
                    src={item.image}
                    alt={item.title}
                    className={classes.image}
                  />
                </figure>
              </Grid>
            </Grid>
          ))}
        </Container>
      </Container>
    </Section>
  );
}

export default ProccessSection;
