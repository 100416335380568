import React from "react";
import Container from "@material-ui/core/Container";
import Card from "@material-ui/core/Card";
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import { emphasize } from "@material-ui/core/styles/colorManipulator";
import Section from "./Section";
import SectionHeader from "./SectionHeader";
import AspectRatio from "./AspectRatio";

const useStyles = makeStyles((theme) => ({
  gridItem: {
    // Add border that contrasts lightly with background color.
    // We use boxShadow so that it's hidden around outer edge
    // due to container <Card> having overflow: hidden
    boxShadow: `1px 1px 0 0 ${emphasize(theme.palette.background.paper, 0.08)}`,
    textAlign: "center",
  },
  imageContainer: {
    maxWidth: "200px",
    margin: "30px auto",
    "& img": {
      height: "100%",
      borderRadius: "10px",
    },
  },
}));

function ServicesSection(props) {
  const classes = useStyles();

  const items = [
    {
      title: "Backend Development",
      body: "With extensive knowledge in REST API, Graph API and Event Driven Development - we can help you build backend systems to fit your needs.",
      image: "images/undraw_visionary_technology_re_jfp7.svg",
    },
    {
      title: "Cloud Based Architecture",
      body: "With many years in cloud development - we can help you build systems in AWS, GCP & Firebase.",
      image: "images/undraw_cloud_hosting_7xb1.svg",
    },
    {
      title: "Mobile App Development",
      body: "Starting as a mobile app development business, building apps is at the core of what we do.",
      image: "images/undraw_mobile_development_re_wwsn.svg",
    },
    {
      title: "Web Development",
      body: "We can build a standalone website using popular tools such as React, or build from scratch using traditional technologies.",
      image: "images/undraw_web_devices_re_m8sc.svg",
    },
  ];

  return (
    <Section
      bgColor={props.bgColor}
      size={props.size}
      bgImage={props.bgImage}
      bgImageOpacity={props.bgImageOpacity}
    >
      <Container>
        {/* <SectionHeader
          title={props.title}
          subtitle={props.subtitle}
          size={4}
          textAlign="center"
        /> */}
        <Card raised={false}>
          <Grid container={true}>
            {items.map((item, index) => (
              <Grid
                item={true}
                xs={12}
                md={6}
                className={classes.gridItem}
                key={index}
              >
                <Box p={6}>
                  <div className={classes.imageContainer}>
                    <AspectRatio ratio={4 / 3}>
                      <img src={item.image} alt={item.title} />
                    </AspectRatio>
                  </div>
                  <Typography variant="h5" gutterBottom={true}>
                    {item.title}
                  </Typography>
                  <Typography variant="subtitle1">{item.body}</Typography>
                </Box>
              </Grid>
            ))}
          </Grid>
        </Card>
      </Container>
    </Section>
  );
}

export default ServicesSection;
