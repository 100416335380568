import React from "react";
import Container from "@material-ui/core/Container";
import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";
import Section from "./Section";
import SectionHeader from "./SectionHeader";

function ClientsSection(props) {
  const items = [
    {
      name: "Tyre Assist",
      image:
        "https://tyre-assist.co.uk/wp-content/uploads/2021/05/00-tyre-logo.svg",
      width: "225px",
    },
    {
      name: "PMB",
      image:
        "https://www.ipadbooth.co.uk/assets/images/pmb-logo-gold-web-576x123.webp",
      width: "175px",
    },
    {
      name: "Help Britain",
      image: "https://helpbritain.org.uk/static/media/logo.17b95697.png",
      width: "135px",
    },
    {
      name: "Ovyo",
      image:
        "https://ovyo.com/wp-content/uploads/2022/04/ovyo-high-reso-logo.svg",
      width: "135px",
    },
  ];

  return (
    <Section
      bgColor={props.bgColor}
      size={props.size}
      bgImage={props.bgImage}
      bgImageOpacity={props.bgImageOpacity}
    >
      <Container>
        <Box textAlign="center">
          <SectionHeader
            title={props.title}
            subtitle={props.subtitle}
            size={4}
            textAlign="center"
          />
          <Grid container={true} justifyContent="center">
            {items.map((item, index) => (
              <Grid item={true} xs={12} md="auto" key={index}>
                <Box py={2} px={3}>
                  <img src={item.image} width={item.width} alt={item.name} />
                </Box>
              </Grid>
            ))}
          </Grid>
        </Box>
      </Container>
    </Section>
  );
}

export default ClientsSection;
