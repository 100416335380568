import React from "react";
import Meta from "../components/Meta";
import HeroSection2 from "../components/HeroSection2";
import FeaturesSection2 from "../components/FeaturesSection2";
import CtaSection from "../components/CtaSection";
import ProductSection from "../components/ProductSection";

function PortfolioPage(props) {
  return (
    <>
      <Meta title="Portfolio" description="View our previous work" />
      <ProductSection
        bgColor="default"
        size="medium"
        bgImage=""
        bgImageOpacity={1}
        title="Portfolio of Work"
        subtitle="All the services you need to move faster"
      />
      <FeaturesSection2
        bgColor="default"
        size="medium"
        bgImage=""
        bgImageOpacity={1}
        title="Features"
        subtitle="All the features you need to move faster"
      />
      <CtaSection
        bgColor="primary"
        size="medium"
        bgImage=""
        bgImageOpacity={1}
        title="Ready to get started?"
        subtitle=""
        buttonText="Contact Us"
        buttonColor="default"
        buttonPath="/contact"
      />
    </>
  );
}

export default PortfolioPage;
