import React from "react";
import Meta from "../components/Meta";
import HeroSection2 from "../components/HeroSection2";
import FeaturesSection2 from "../components/FeaturesSection2";
import CtaSection from "../components/CtaSection";
import TeamBiosSection from "./../components/TeamBiosSection";

function AboutPage(props) {
  return (
    <>
      <Meta title="About" description="Learn about our company and team" />
      <TeamBiosSection
        bgColor="default"
        size="medium"
        bgImage=""
        bgImageOpacity={1}
        title="Meet the Team"
        subtitle=""
      />
      <CtaSection
        bgColor="primary"
        size="medium"
        bgImage=""
        bgImageOpacity={1}
        title="Check out our portfolio"
        subtitle=""
        buttonText="Explore"
        buttonColor="default"
        buttonPath="/portfolio"
      />
    </>
  );
}

export default AboutPage;
