import React from "react";
import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import Section from "./Section";
import SectionHeader from "./SectionHeader";

const useStyles = makeStyles((theme) => ({
  itemsContainer: {
    marginTop: 60,
  },
  row: {
    // Reverse every other row
    "&:nth-of-type(even)": {
      flexDirection: "row-reverse",
    },

    // Spacing between rows
    "&:not(:last-child)": {
      marginBottom: `${theme.spacing(3)}px`,
    },
  },
  figure: {
    maxWidth: 300,
    margin: "30px auto",
  },
  image: {
    height: "auto",
    maxWidth: "100%",
    display: "block",
    marginLeft: "auto",
    marginRight: "auto"
  },
  link: {
    marginTop: "15px"
  }
}));

function ProductSection(props) {
  const classes = useStyles();

  const items = [
    {
      title: "KPBE Website",
      description:
        "For KPB Electrical's site, we built a modern, clean and simple website which is mobile friendly for ease of use. By providing a single page site, we were able to keep all the key information in one place, without having to make a user click through to find information which is important when you need to find an electrian in a hurry!",
      image: "images/kpbe.png",
      link: "https://www.kpbe.co.uk/",
      linkTitle: "Visit KPBE's site"
    },
    {
      title: "Tyre Assist",
      description:
        "Tyre Assist has a complex brief - which started with innovation - change how tyre callouts are done. With Graeme at Tyre Assist we were able to design, build and launch a product that fit that brief; giving users the ability to raise SOS alerts to Tyre Assist whenever and wherever they are. App launched on both iOS App Store & Android PlayStore.",
      image: "images/tyreassist-v2.png",
      link: "https://apps.apple.com/gb/app/tyre-assist/id1611627384",
      linkTitle: "View app on Apple's App Store",
      link2: "https://play.google.com/store/apps/details?id=com.tminal.tyre_assist&pli=1",
      linkTitle2: "View app on Google's PlayStore"
    },
    {
      title: "Taxi Calculator",
      description: "Fare and journey calculations can be tricky for Taxi businesses - with real world influence, we built a journey calculator service that is used within the industry. Being able to predict traffic, mileage and time allows accurate costing, not only keep fares lower for a customer but also simplifying the process along the way.",
      image: "images/Screenshot-taxi.png",
      link: "https://taxi.tminal.com",
      linkTitle: "Visit our site"
    },
  ];

  return (
    <Section
      bgColor={props.bgColor}
      size={props.size}
      bgImage={props.bgImage}
      bgImageOpacity={props.bgImageOpacity}
    >
      <Container>
        <SectionHeader
          title={props.title}
          subtitle={props.subtitle}
          size={4}
          textAlign="center"
        />
        <Container
          maxWidth="md"
          disableGutters={true}
          className={classes.itemsContainer}
        >
          {items.map((item, index) => (
            <Grid
              className={classes.row}
              container={true}
              item={true}
              alignItems="center"
              spacing={4}
              key={index}
            >
              <Grid item={true} xs={12} md={6}>
                <Box
                  textAlign={{
                    xs: "center",
                    md: "left",
                  }}
                >
                  <Typography variant="h5" gutterBottom={true}>
                    {item.title}
                  </Typography>
                  <Typography variant="subtitle1">
                    {item.description}
                  </Typography>
                  <div className={classes.link}>
                    <a href={item.link}>
                      <Typography variant="subtitle2">
                        {item.linkTitle}
                      </Typography>
                    </a>
                    {item.link2 ? <>
                      <a href={item.link2}>
                      <Typography variant="subtitle2">
                        {item.linkTitle2}
                      </Typography>
                    </a>
                    </> : <></>}
                  </div>
                </Box>
              </Grid>
              <Grid item={true} xs={12} md={6}>
                <figure className={classes.figure}>
                  <img
                    src={item.image}
                    alt={item.title}
                    className={classes.image}
                  />
                </figure>
              </Grid>
            </Grid>
          ))}
        </Container>
      </Container>
    </Section>
  );
}

export default ProductSection;
