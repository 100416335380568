import React from "react";
import Meta from "../components/Meta";
import HeroSection2 from "../components/HeroSection2";
import ServicesSection from "../components/ServicesSection";
import CtaSection from "../components/CtaSection";
import ProccessSection from "../components/ProcessSection";

function ServicesPage(props) {
  return (
    <>
      <Meta title="Services" description="View the services we offer" />
      <HeroSection2
        bgColor="default"
        size="medium"
        bgImage=""
        bgImageOpacity={1}
        title="Services we offer"
        subtitle=""
        image=""
        buttonText=""
        buttonColor="primary"
        buttonPath="/contact"
      />
      <ServicesSection
        bgColor="default"
        size="medium"
        bgImage=""
        bgImageOpacity={1}
        title="Features"
        subtitle="All the features you need to move faster"
      />
      <ProccessSection
        bgColor="default"
        size="medium"
        bgImage=""
        bgImageOpacity={1}
        title="The Process"
        subtitle=""
      />
      <CtaSection
        bgColor="primary"
        size="medium"
        bgImage=""
        bgImageOpacity={1}
        title="Ready to get started?"
        subtitle=""
        buttonText="Contact Us"
        buttonColor="default"
        buttonPath="/contact"
      />
    </>
  );
}

export default ServicesPage;
