import React from "react";
import Meta from "./../components/Meta";
import HeroSection from "./../components/HeroSection";
import ClientsSection from "./../components/ClientsSection";
import FeaturesSection from "./../components/FeaturesSection";
import TeamBiosSection2 from "./../components/TeamBiosSection2";
import TestimonialsSection from "./../components/TestimonialsSection";
import CtaSection2 from "./../components/CtaSection2";
import ContactSection2 from "./../components/ContactSection2";

function IndexPage(props) {
  return (
    <>
      <Meta />
      <HeroSection
        bgColor="inherit"
        size="large"
        bgImage="images/photo-1581291518857-4e27b48ff24e.jpeg"
        bgImageOpacity={0.2}
        title="Let's get building!"
        subtitle="We provide modern frontend and backend software solutions running on well architected cloud-based infrastructure."
        buttonText="Get Started"
        buttonColor="default"
        buttonPath="/pricing"
      />
      <ClientsSection
        bgColor="secondary"
        size="medium"
        bgImage=""
        bgImageOpacity={1}
        title="You're in safe hands"
        subtitle=""
      />
      <FeaturesSection
        bgColor="default"
        size="medium"
        bgImage=""
        bgImageOpacity={1}
        title="Features"
        subtitle="All the services you need to move faster"
      />
      <TeamBiosSection2
        bgColor="default"
        size="medium"
        bgImage=""
        bgImageOpacity={1}
        title="Meet the Team"
        subtitle=""
      />
      <TestimonialsSection
        bgColor="default"
        size="medium"
        bgImage=""
        bgImageOpacity={1}
        title="Here's what people are saying"
        subtitle=""
      />
      <CtaSection2
        bgColor="primary"
        size="medium"
        bgImage="images/photo-1512941937669-90a1b58e7e9c.jpeg"
        bgImageOpacity={0.3}
        title="Check out our portfolio"
        subtitle=""
        buttonText="Explore"
        buttonColor="default"
        buttonPath="/portfolio"
      />
      <ContactSection2
        bgColor="default"
        size="medium"
        bgImage=""
        bgImageOpacity={1}
        title="Contact Us"
        subtitle="Got an idea or ready to start? Please contact us!"
        buttonText="Send message"
        buttonColor="primary"
        showNameField={true}
        embedSrc="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2453.3102602062336!2d1.1475190123592958!3d52.055874071826246!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x47d9a02d71027b5d%3A0x375f82669f65705c!2s50%20Princes%20St%2C%20Ipswich%20IP1%201RJ!5e0!3m2!1sen!2suk!4v1682017540325!5m2!1sen!2suk"
      />
    </>
  );
}

export default IndexPage;
