import React from "react";
import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import Typography from "@material-ui/core/Typography";
import CardHeader from "@material-ui/core/CardHeader";
import Avatar from "@material-ui/core/Avatar";
import { makeStyles } from "@material-ui/core/styles";
import Section from "./Section";
import SectionHeader from "./SectionHeader";

const useStyles = makeStyles((theme) => ({
  header: {
    paddingTop: 0,
  },
  avatar: {
    width: theme.spacing(7),
    height: theme.spacing(7),
  },
}));

function TestimonialsSection(props) {
  const classes = useStyles();

  const items = [
    {
      avatar: "images/keith.jpeg",
      name: "Keith Barnes",
      testimonial:
        "Steve visited me at home to talk through my digital needs when I was starting my company. Tminal very quickly had me up and running with a website, email addresses, Facebook page and virtual WhatsApp number all seamlessly integrated. A huge stress taken away from me. I’ve recommended them to many people.",
      company: "KPBE",
    },
    {
      avatar: "images/tyrevan.png",
      name: "Graeme Clarke",
      testimonial:
        "Just want to thank Tminal and Jamie for all there support and understanding the concept of the App wanted … took onboard all that was asked in a effortless fashion … fantastic service … Thank you",
      company: "Tyre Assist",
    },
    // {
    //   avatar: "https://uploads.divjoy.com/pravatar-150x-12.jpeg",
    //   name: "Blake Elder",
    //   testimonial:
    //     "Lorem ipsum dolor sit amet, consectetur adipisicing elit. Dolorum consequatur numquam aliquam tenetur ad amet inventore hic beatae.",
    //   company: "Company",
    // },
  ];

  return (
    <Section
      bgColor={props.bgColor}
      size={props.size}
      bgImage={props.bgImage}
      bgImageOpacity={props.bgImageOpacity}
    >
      <Container>
        <SectionHeader
          title={props.title}
          subtitle={props.subtitle}
          size={4}
          textAlign="center"
        />
        <Grid container={true} justifyContent="center" spacing={4}>
          {items.map((item, index) => (
            <Grid item={true} xs={12} sm={4} key={index}>
              <Card>
                <CardContent>
                  <Typography variant="body1" component="p">
                    "{item.testimonial}"
                  </Typography>
                </CardContent>
                <CardHeader
                  className={classes.header}
                  avatar={
                    <Avatar
                      src={item.avatar}
                      alt={item.name}
                      className={classes.avatar}
                    />
                  }
                  title={item.name}
                  subheader={item.company}
                />
              </Card>
            </Grid>
          ))}
        </Grid>
      </Container>
    </Section>
  );
}

export default TestimonialsSection;
