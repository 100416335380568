import React from "react";
import Container from "@material-ui/core/Container";
import Card from "@material-ui/core/Card";
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import { emphasize } from "@material-ui/core/styles/colorManipulator";
import Section from "./Section";
import SectionHeader from "./SectionHeader";
import AspectRatio from "./AspectRatio";
import { Link } from 'react-router-dom';
import { Redirect } from "react-router-dom/cjs/react-router-dom.min";

const useStyles = makeStyles((theme) => ({
  gridItem: {
    // Add border that contrasts lightly with background color.
    // We use boxShadow so that it's hidden around outer edge
    // due to container <Card> having overflow: hidden
    boxShadow: `1px 1px 0 0 ${emphasize(theme.palette.background.paper, 0.08)}`,
    textAlign: "center",
  },
  imageContainer: {
    maxWidth: "200px",
    margin: "30px auto",
    "& img": {
      height: "100%",
      borderRadius: "10px",
    },
  },
  link: {
    marginTop: "25px"
  }
}));

function FeaturesSection2(props) {
  const classes = useStyles();

  const items = [
    {
      title: "Photo Media Booth",
      body: "Photo Media Booth is a professional photo booth app for iPadOS. Allowing users to get the photo booth experience with a much smaller event footprint - being able to take photos, GIFs and videos and share them in a simple UI flow. With a long standing relationship with Photo Media Booth - we are used to develop and upgrade the PMB iOS App.",
      image: "images/photomediabooth.jpg",
      link: "https://apps.apple.com/gb/app/photo-media-booth/id1160902570",
      linkTitle: "Download the app"
    },
    {
      title: "Social Media Booth",
      body: "Social Media Booth is an allow watching on Dropbox folders and ability for users to share and view images in a slideshow app for at events. This app was developed as an app alongside PMB - suitable for booth owners and photographers.",
      image: "images/socailmediabooth.jpg",
      link: "https://apps.apple.com/gb/app/social-media-booth/id1247265597",
      linkTitle: "Download the app"
    },
    {
      title: "TagTap",
      body: "TagTap is a product we have built ourselves for users to simply give and provide contact details. This is used by both QR Code & RFID Badges that can be heat pressed onto clothing. When scanning a code a user will be able to see phone numbers, email address, location and many other details that you can provide.",
      image: "images/tagtap\ logo\ snet.png",
      link: "https://tagtap.uk/",
      linkTitle: "View our site"
    },
  ];

  return (
    <Section
      bgColor={props.bgColor}
      size={props.size}
      bgImage={props.bgImage}
      bgImageOpacity={props.bgImageOpacity}
    >
      <Container>
        {/* <SectionHeader
          title={props.title}
          subtitle={props.subtitle}
          size={4}
          textAlign="center"
        /> */}
        <Card raised={false}>
          <Grid container={true}>
            {items.map((item, index) => (
              <Grid
                item={true}
                xs={12}
                md={6}
                className={classes.gridItem}
                key={index}
              >
                <Box p={6}>
                  <div className={classes.imageContainer}>
                    <AspectRatio ratio={4 / 3}>
                      <img src={item.image} alt={item.title} />
                    </AspectRatio>
                  </div>
                  <Typography variant="h5" gutterBottom={true}>
                    {item.title}
                  </Typography>
                  <Typography variant="subtitle1">{item.body}</Typography>
                  <div className={classes.link}>
                    <a href={item.link} ><Typography variant="subtitle2">{item.linkTitle}</Typography></a>
                  </div>
                </Box>
              </Grid>
            ))}
          </Grid>
        </Card>
      </Container>
    </Section>
  );
}

export default FeaturesSection2;
